<template>
  <div>
    <title-header :title="title"></title-header>
    <v-container class="ml-8">
      <v-row class="mb-2">
        <v-col cols="3">
          <v-toolbar flat>
            <v-toolbar-title>Total de registros: {{ content.length }}</v-toolbar-title>
          </v-toolbar>
          <span>
            <a :href="`${publicPath}template/Template%20-%20Usuarios%20-%20users.csv`"> Template </a>
          </span>
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="3">
          <v-file-input
            label="Archivo para importar usuarios"
            truncate-length="15"
            show-size
            @change="handleFileUpload"
          ></v-file-input>
        </v-col>
      </v-row>
    </v-container>

    <v-data-table
      :headers="headers"
      :items="content"
      class="elevation-1 pl-10 pr-10"
      :loading="loading"
      :loading-text="loadingMessage"
    > </v-data-table>

    <v-divider width="80%"></v-divider>
    <v-container>
      <v-progress-linear
        v-model="value"
        :active="show"
        :indeterminate="query"
        :query="true"
      ></v-progress-linear>

      <div class="mt-5 mb-20">
        <v-btn class="mr-6" rounded outlined color="primary" width="10rem" dark @click="cancel()">Cancelar</v-btn>
        <v-btn

          rounded
          color="primary"
          width="10rem"
          @click="dialogSave = true"
          :disabled="!(content.length > 0)"
        >Subir usuarios</v-btn>
      </div>
    </v-container>

    <v-dialog v-model="dialogSave" persistent max-width="480">
      <v-card>
      <v-card-title class="headline">Importante</v-card-title>
      <v-card-text>Por favor tenga en cuenta que el proceso de carga masiva de usuarios puede tardar varios minutos.
        dependiendo de la cantidad de datos que se estén procesando. Le agradecemos su paciencia y le recomendamos que no cierre la ventana o la pestaña del navegador
        mientras se realiza el proceso de carga. Gracias por su comprensión. </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary darken-1" text @click="dialogSave = false">Cancelar</v-btn>
          <v-btn color="primary darken-1" text @click="save()">Aceptar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog" persistent max-width="480">
      <v-card>
      <v-card-title class="headline">Felicidades</v-card-title>
      <v-card-text>Los usuarios se han agregado satisfactoriamente.</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary darken-1" text @click="cancel()">Aceptar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar color="primary" v-model="showSnackbar">
      {{snackBarText}}
      <v-btn text @click="showSnackbar = false">Cerrar</v-btn>
    </v-snackbar>
  </div>
</template>
<script>
import TitleHeader from '@/components/app/title-header/title-header'
import Papa from 'papaparse'
import { UsersService } from '@/api/api.service'

export default {
  name: 'UserImport',
  props: {},
  data() {
    return {
      title: 'Importar Usuarios',
      parseCsv: {},
      file: '',
      content: [],
      dialog: false,
      snackBarText: '',
      publicPath: process.env.BASE_URL,
      showSnackbar: false,
      loading: false,
      loadingMessage: 'Subiendo Archivo',
      dialogSave: false,
      show: false,
      query: false,
      interval: 0,
      value: 0,
      parsed: false,
      headersFile: ['display_name', 'email', 'mobile_phone', 'password', 'roles', 'grupos'],
      headers: [
        { text: 'Nombre Completo', value: 'display_name' },
        { text: 'Correo', value: 'email' },
        { text: 'Número telefónico', value: 'mobile_phone' },
        { text: 'Contraseña', value: 'password' },
        { text: 'Rol', value: 'roles' },
        { text: 'Grupo', value: 'grupos' },
        { text: '', value: 'buttons', width: 265 }
      ]
    }
  },
  components: {
    TitleHeader
  },
  beforeDestroy() {
    clearInterval(this.interval)
  },
  methods: {
    queryAndIndeterminate (progress) {
      this.query = true
      this.show = true
      this.value = 0

      setTimeout(() => {
        this.query = false

        this.interval = setInterval(() => {
          if (this.value === 100) {
            clearInterval(this.interval)
            this.show = false
            return setTimeout(this.queryAndIndeterminate, 2000)
          }
          this.value += 25
        }, 1000)
      }, 2500)
    },
    handleFileUpload(file) {
      this.file = file
      this.parseFile()
    },
    cancel() {
      this.$router.push('/settings/users')
    },
    save() {
      this.dialogSave = false
      this.loading = true
      UsersService.importUsers(this.file)
        .then(r => {
          this.dialog = true
        })
        .catch(e => {
          this.snackBarText = e.message
          this.showSnackbar = true
          this.loading = false
        })
    },
    parseFile() {
      this.loading = true
      Papa.parse(this.file, {
        header: true,
        skipEmptyLines: true,
        complete: function (results) {
          if (JSON.stringify(results.meta.fields) === JSON.stringify(this.headersFile)) {
            this.content = results.data
            this.parsed = true
          } else {
            alert('Formato del archivo invalido')
          }
          this.loading = false
        }.bind(this)
      })
    }
  }
}
</script>
